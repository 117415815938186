@import "../../const/settings.scss";
.item-result-search {
  .logo-on-search {
    position: relative !important;
    width: 40px !important;
    height: 40px !important;
    margin-right: 8px;
    border-radius: 5px;
    object-fit: contain !important;
  }
  display: flex;
  align-items: center;
}
.wrapper-form-search {
  .ant-select-selector {
    height: 56px !important;
    padding: 16px !important;
    border-radius: 8px !important;
    background: var(--color-white, #fff) !important;
    border: unset !important;
    .ant-select-selection-search {
      left: 16px;
      right: 16px;
    }
  }
  .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
    color: var(--color-text, #6884a7);
    font-size: 16px;
  }
  input {
    height: 56px !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    color: var(--color-text, #6884a7);
  }
  .button-submit-search {
    width: 100%;
    height: 56px;
    border-radius: 8px;
    background: var(--color-brand-color, #00c5d7);
    border: unset;
    span {
      color: #fff;

      /* P1/Bold */
      // font-family: Roboto Flex;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
    }
  }
  .ant-picker {
    width: 100%;
    padding: 0 11px;
    border-radius: 8px;
    border: unset;
  }
  .ant-picker-input {
    ::placeholder {
      color: var(--color-text, #6884a7);
      /* P2/Normal */
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
    }
  }
  .ant-picker-time-panel-cell-inner {
    color: var(--color-text, #6884a7) !important;
  }
  .ant-select-selection-item {
    color: var(--color-text, #6884a7);
    /* P2/Normal */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    display: flex;
    align-items: center;
  }
  .button-choose {
    border-radius: 8px;
    background: var(--Color-White, #fff);
    height: 56px !important;
    display: flex;
    align-items: center;
    padding: 16px;
    cursor: pointer;
    color: var(--Color-Text, #2a3467);
    /* P2/Normal */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    .un-choose {
      color: #6884a7;
    }
  }
}

.wp-popular-tag,
.reputable-dentistry {
  .ant-tabs-nav {
    justify-content: center;
    &:before {
      content: unset !important;
    }
  }
  .ant-tabs-content {
    display: flex;
    justify-content: center;
  }
  .ant-tabs-nav-wrap {
    display: flex;
    justify-content: center;
  }
  .ant-tabs-tab-btn {
    h4 {
      color: var(--color-bg-navy, #2a3467) !important;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
    }
    h3 {
      color: var(--color-bg-navy, #2a3467) !important;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      margin-bottom: 0;
    }
  }
  .ant-tabs-ink-bar {
    background: #00c5d7 !important;
  }
}
.wp-popular-tag {
  .ant-tabs-nav-wrap {
    @include media(ssm_375) {
      justify-content: unset;
    }
  }
}
.reputable-dentistry {
  .ant-tabs-nav-wrap {
    @include media(sm) {
      justify-content: unset;
    }
  }
}

@include media(lg) {
}

@include media(md) {
  .wrapper-form-search {
    .ant-select-selection-placeholder {
      font-size: 14px !important;
    }
    .ant-select-item-option-content {
      font-size: 14px !important;
    }
    .button-choose {
      font-size: 14px !important;
    }
    input {
      font-size: 14px !important;
    }
    .ant-form-item {
      margin-bottom: 12px !important;
    }
  }
}

// .ant-popover {
//   animation-duration: 0s !important;
// }

.reputable-dentistry-top {
  @include media(mdx) {
    .ant-row {
      max-width: 500px;
    }
  }
}
